.scroll_x_container {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap; 
    box-sizing: border-box;
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
}

.scroll_x_container:hover {
    cursor: grab;
}
/* ( 크롬, 사파리, 오페라, 엣지 ) 동작 */
.scroll_x_container::-webkit-scrollbar {
    display: none;
}