.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 0;
    pointer-events: none;
    z-index: 5500;
}

.main_content {
    width: 70%;
    padding: 10px;
    background-color: black;
    font-size: 14px;
    color: white;
    border-radius: 14px;
    text-align: center;
    transition: opacity 500ms;
}

.visible {
    opacity: 80%;
}

.invisible {
    opacity: 0;
}