.container {
    position: relative;
    padding: 5px 20px 20px 20px;
    background-color: var(--SubBg01);
    border-radius: 20px;
}

.container.network {
    position: relative;
    padding: 20px;
    background-color: var(--SubBg01);
    border-radius: 20px;
}

.container.none {
    position: relative;
    background-color: var(--MainBg);
}

.container .close_btn_wrap {
    position: absolute;
    top: 10px;
    right: 10px;
}

.container .close_timer_wrap {
    width: 22px;
    height: 22px;
    background-color: #818C9A;
    border-radius: 1000px;
    position: relative;
}

.close_timer_wrap .time_text_wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.time_text {
    font-weight: 500;
    color: var(--SubBg01);
}