.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60px;
    min-width: 60px;
    max-width: 60px;
    height: 24px;
    min-height: 24px;
    max-height: 24px;
    color: var(--MainText);
    font-weight: 700;
    font-size: 11px;
    cursor: pointer;
    background-color: var(--PointColor01);
}

.popupDescription {
    line-height: 24px;
}

.choice0Style {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 56px;
    width: 100%;
    background-color: var(--MainButton);
    border-radius: 12px;
    font-size: 16px;
    font-weight: 600;
    color: var(--ButtonText01);
}

.choice1Style {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
}

.choice1CloseIconStyle {
    width: 15px;
    height: 15px;
    object-fit: contain;
}

.choice1TextStyle {
    font-size: 14px;
    font-weight: 500;
    color: var(--SubText01);
    user-select : none;
}

.qrContainer {
    width: 144px;
    height: 144px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: auto;
}

.qrTitle {
    text-align: center;
}

@media (max-width: 359px) {
    .popupDescription {
      width: 150px;
    }
  }