.aside_page__menu_container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
}

.aside_page__menu {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.aside_page__menu__icon_image {
    width: 48px;
    height: 48px;
    background-color: var(--SubBg01);
    border-radius: 1000px;
}

.aside_page__menu__title {
    font-size: 20px;
    font-weight: 500;
    padding-left: 15px;
    white-space: pre-wrap;
}

.aside_page__menu__title_bolded {
    font-weight: 700;
}

.aside_page__menu__button {
    padding: 9px 11px 9px 11px;
    margin-left: 8px;
    background-color: rgba(144, 182, 255, 0.1);
    font-weight: 700;
    color: #90B6FF;
    border-radius: 7px;
    cursor: pointer;
}