.positionedContainer {
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    pointer-events: none;
}

.container {
    pointer-events: all;
    background-color: var(--SubBg01);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 300ms ease-out, opacity 300ms linear;
    position: relative;
}

.title {
    font-size: 22px;
    font-weight: 700;
    color: var(--MainText);
    white-space: pre-wrap;
    text-align: center;
}

.description {
    font-size: 15px;
    font-weight: 500;
    color: var(--MainText);
    white-space: pre-wrap;
    text-align: center;
    width: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.closeButton {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    width: 14px;
    height: 14px;
    margin-top: 30px;
    margin-right: 33px;
    object-fit: cover;
}