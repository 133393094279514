.titlebar.sub {
    background-color: #ffffff;
    position: fixed;
    width: calc(100% - 48px);
    padding-left: 24px;
    padding-right: 24px;
    z-index: 1000;

    /* position: relative; */
    display: flex;
    align-items: center;
    flex-direction: row;
    min-width: 272px;
    max-width: 432px;
    height: 56px;
    /* box-shadow: 0 3px 10px 0 #F6F6F6 */
    /* border-bottom: 1px solid #EEEEEE; */
}

.titlebar.fixed {
    background-color: #ffffff;
    position: fixed;
    width: calc(100% - 48px);
    max-width: 432px;
    padding-left: 24px;
    padding-right: 24px;
    z-index: 1000;
}

.titlebar.sub .left-icon {
    display: flex;
    align-items: center;
    flex-grow: 1;
    width: 64px;
    height: 56px;
}

.trailing-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-left: 10px;
    cursor: pointer;
}

.trailing-icon > img {
    object-fit: contain;
    width: 24px;
    height: 24px;
}

.big-icon > img {
    object-fit: contain;
    width: 66px;
    height: 56px;
    transform: translateX(24px);
}

.trailing-icon > img.fit_none {
    object-fit: none;
}

.titlebar.sub > span {
    display: flex;
    justify-content: center;
    flex-grow: 1;
}

.titlebar.sub .right-icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    width: 64px;
    height: 56px;
}

.titlebar.sub .left-icon > img {
    cursor: pointer;
}

.titlebar.sub .left-icon > img,
.titlebar.sub .right-icon > img{
    width: 24px;
    height: 24px;
}

.titlebar.sub .right-icon > :last-child {
    margin-left:10px;
}

.titlebar.sub .text-area {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.titlebar.sub .text-area span {
    font-size: calc(16px + var(--CurrentScaleOption));
    font-weight: var(--FontWeightExtraBold);
    color: var(--MainText);
    padding-right: 4px;
}

.titlebar.sub .text-area .guide-btn {
    cursor: pointer;
}

.titlebar.sub .text-area.fullWidth {
    width:100%;
    justify-content:center;
}