.list_element_container {
    width: 100%;
}

.list_element_stackable {
    position: relative;
    scroll-snap-align: center none;
}

.list_element_y_centered {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
}

.unselected, .selected {
    transition: opacity 0.1s ease;
  }

.selected {
opacity: 1;
}

.unselected {
opacity: 0.5;
}

.list_container {
    position: relative;
}

.list_style {
    width: 100%;
    scroll-snap-type: y mandatory;
    height: 175px;
    overflow-y: scroll;
    text-align: center;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.list_style::-webkit-scrollbar {
    display: none;
}

.list_style:hover {
    cursor: grab;
}

.selection_indicator_rect {
    position: absolute;
    top: 50%;
    left: 0%;
    width: 100%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    user-select: none;
    pointer-events: none;
}

@keyframes smoothScroll {
    from {
      scroll-behavior: auto;
    }
    to {
      scroll-behavior: smooth;
    }
  }