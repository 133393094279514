.check_box_container {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.require_phone_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--SubBg01);
}

.require_confirm_container {
    width: 100%;
    height: 100%;
    background-color: var(--SubBg01);
}

.require_phone_container > h1, .require_confirm_container > h1 {
    font-size: 22px;
    font-weight: 700;
    line-height: 30.8px;
    padding-top: 30px;
    padding-left: 24px;
    padding-right: 24px;
    white-space: pre-wrap;
    margin: 0;
}

.require_phone_container > h2, .require_confirm_container > h2 {
    padding-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: #818C9A;
    margin: 0;
}

.require_phone_container > section, .require_confirm_container > section {
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-direction: column;
}

.require_confirm_container > section > h3, .phone_number_title_and_guide > h3 {
    font-weight: 500;
    font-size: 17px;
    line-height: 17.9px;
    color: #818C9A;
    margin: 0;
    padding-top: 68px;
}

.tip {
    padding-top: 8px;
    font-size: 12px;
    font-weight: 500;
    color: #BCC3D3;
    padding-bottom: 24px;
}

.divider {
    margin-top: 11px;
    height: 1px;
    background-color: #EAEEF7;
}

.input_container {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    font-size: 17px;
    font-weight: 600;
    padding-top: 17px;
    outline: none;
}

.flag {
    font-size: 16px;
}

.input_container > input {
    outline: none;
    font-size: 17px;
    font-weight: 600;
    border: 0;
    padding: 0;
}

.check {
    appearance: none;
    width: 20px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('/images/member_integrated/member/checkbox_24.png');
}

.check:checked {
    background-image: url('/images/member_integrated/member/checkbox_24_on.png');
}

.require_confirm_container > button {
    padding: 0;
    border: 0;
    background: #F8F8F8;
    height: 36px;
    border-radius: 8px;
    margin-left: 24px;
    margin-top: 16px;
    cursor: pointer;
}

.require_confirm_container > button > span {
    font-size: 13px;
    font-weight: 700;
    color: #818C9A;
    padding: 10px 12px 10px 12px;
}

.clear_confirm_num_button {
    margin: 0;
    border: 0;
    padding: 0;
    background-color: rgba(0, 0, 0, 0);
    height: 20px;
    cursor: pointer;
}

.clear_confirm_num_button > img {
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.input_container_2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding-top: 17px;
}

.confirm_number_input {
    outline: none;
    border: 0;
    font-size: 17px;
    font-weight: 600;
    width: 100%;
}

.require_confirm_container > section > div > span {
    font-size: 15px;
    font-weight: 500;
    color: #818C9A;
}

.require_confirm_container > section > span {
    margin-top: 8px;
    font-size: 12px;
    font-weight: 500;
    color: #BCC3D3;
    white-space: pre-wrap;
    padding-bottom: 24px;
}

.phone_number_title_and_guide {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.phone_number_title_and_guide > button {
    margin: 68px 0 0 0;
    border: 1px solid #BCC3D3;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 1000px;
    background-color: white;
    width: 22px;
    height: 22px;
    cursor: pointer;
}

.phone_number_title_and_guide > button > span {
    object-fit: contain;
    color: #BCC3D3;
    font-size: 12px;
    font-weight: 700;
}

.guide {
    position: absolute;
    width: 285px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    background-color: white;
    border: 1px solid black;
    border-radius: 8px;
}

.guide__upper {
    padding-top: 16px;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: 800;
    font-size: 12px;
}

.guide__bottom {
    padding-bottom: 16px;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: 500;
    font-size: 12px;
}

.titlebar {
    width: 100%;
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(16px + var(--CurrentScaleOption));
    font-weight: var(--FontWeightExtraBold);
    position: relative;
    background-color: var(--SubBg01);
    z-index: 10000;
}

.goback_btn {
    position: absolute;
    left: 0;
    width: 24px;
    height: 24px;
    margin-left: 24px;
    cursor: pointer;
}

.bottombar {
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(16px + var(--CurrentScaleOption));
    font-weight: var(--FontWeightBold);
    cursor: pointer;
    position: fixed;
    bottom: 0;
}