html {
  font-size: 16px; 
}

body {
  margin: 0;
  height: 100vh;
  font-family: 'Pretendard';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F9F9F9;
  overflow-x: hidden;
  overflow-y: scroll;
  user-select: none;
  -ms-overflow-style: none;
  scrollbar-width: 0;
  word-break: keep-all;
}

body::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 100;
  src: url('/fonts/pretendard_thin.ttf');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 200;
  src: url('/fonts/pretendard_extra_light.ttf');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/pretendard_light.ttf');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/pretendard_regular.ttf');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/pretendard_medium.ttf');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/pretendard_semi_bold.ttf');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/pretendard_bold.ttf');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 800;
  src: url('/fonts/pretendard_extra_bold.ttf');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 900;
  src: url('/fonts/pretendard_black.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/montserrat_light.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/montserrat_regular.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/montserrat_medium.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/montserrat_semi_bold.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/montserrat_bold.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('/fonts/montserrat_extra_bold.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url('/fonts/montserrat_black.ttf');
}

#root {
  place-items: center;
  display: grid;
  margin: 0;
  padding: 0;
}

.main-container {
  max-width: 480px;
  min-width: 320px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-page {
  width: 100%;
  max-width: 480px;
  min-width: 320px;
  /* background-color: #ffffff; */
  position: relative;
  overflow: hidden;
  /* border-right: 1px solid #EEEEEE; */
  /* border-left: 1px solid #EEEEEE; */
  overflow-x: hidden;
}

.page-type-main {
  position: relative;
  /* overflow: hidden; */
  min-height: 100%;
}

.page-type-sub {
  position: relative;
  /* overflow: hidden; */
  min-height: 100%;
}

.page-type-sub-half { /* 탑 Or 바텀 버튼이 없는 경우 */
  position: relative;
  /* overflow: hidden; */
  min-height: 100%;
}

.background-area {
  height: 100vh;
  width: 100%;
  position: absolute;
  background-color: #F4F7FE;
  z-index: -2;
  left: -1px;
  /* border-right: 1px solid #EEEEEE;
  border-left: 1px solid #EEEEEE; */
}


@media (min-width: 1140px) {
  .main-container {
    align-items: normal;
  }
  .main-page {
    margin: 0px 0px 0px calc(50% + 90px);
  }
}

.main-contents {
  margin: 0 24px 0 24px;
}