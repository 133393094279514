.payment_complete_popup_container {
}


.big_title {
  margin-bottom: 40px;
  font-size: calc(22px + var(--CurrentScaleOption));
  font-weight: var(--FontWeightBold);
}

.title {
  font-size: calc(15px + var(--CurrentScaleOption));
  font-weight: var(--FontWeightSemiBold);
  color: var(--MainText);
}

.normal_text {
  font-size: calc(12px + var(--CurrentScaleOption));
  font-weight: var(--FontWeightRegular);
  color: var(--MainText);
}

.contents {
  width: 100%;
  background-color: var(--MainBg);
  border-radius: calc(12px + var(--CurrentScaleOption));
}

.text_area {
  padding: calc(16px + var(--CurrentScaleOption));
  font-size: calc(12px + var(--CurrentScaleOption));
  font-weight: var(--FontWeightRegular);
  color: var(--MainText);
}

.bold {
  font-weight: var(--FontWeightSemiBold);
}

.width100 {
  width: 100%;
}

.button_container {
  width: 100%;
}

.close_button_wrap {
  cursor: pointer;
}

.close_button_wrap > img {
  width: 24px;
  height: 24px;
}

.copy_button {
  background-color: var(--ThirdButton);
  padding: 2px 8px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 5px;
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 56px;
  border-radius: 12px;
  line-height: 19px;
  font-size: calc(14px + var(--CurrentScaleOption));
  font-weight: var(--FontWeightMedium);
  color: var(--ButtonText01);
  background-color: var(--PointColor01);
  cursor: pointer;
}