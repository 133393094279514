.recommend_unse_view_container {
    width: calc(270px + var(--CurrentScaleOption));
    height: calc(175px + var(--CurrentScaleOption));
    border-radius: 12px;
    background-color: var(--SubBg03);
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.08);
}

.recommend_unse_view_container .top_area {
    padding: 16px 16px 0 16px;
}

.recommend_unse_view_container .main_text {
    font-weight: var(--FontWeightBold);
    font-size: calc(22px + var(--CurrentScaleOption));
    line-height: 26.25px;
    color: var(--MainText);
    margin-right: 13px;
}

.recommend_unse_view_container .highlight {
    background: linear-gradient(#FFF854, #FFF854);
    background-size: auto 50%;
    background-position-y: 100%;
    background-repeat: no-repeat;
}

.recommend_unse_view_container .sub_text {
    font-weight: var(--FontWeightMedium);
    font-size: calc(14px + var(--CurrentScaleOption));
    line-height: 16.8px;
    color:var(--SubText01);
    padding-bottom: calc(4px + var(--CurrentScaleOption));
}

.recommend_unse_view_container .count_text {
    padding: 4px 8px;
    border-radius: 12px;
    border: 1px solid var(--Line02);
    font-weight: var(--FontWeightMedium);
    font-size: calc(10px + var(--CurrentScaleOption));
    color:var(--SubText01);
}

.recommend_unse_view_container .bottom_area {
    padding: 0 16px;
    height: calc(88px + var(--CurrentScaleOption));
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.recommend_unse_view_container:nth-child(1) .bottom_area {
    background-color: #FCD7D7;
}

.recommend_unse_view_container:nth-child(2) .bottom_area {
    background-color: #CBE5F9;
}

.recommend_unse_view_container:nth-child(3) .bottom_area {
    background-color: #FFE1CB;
}

.recommend_unse_view_container:nth-child(4) .bottom_area {
    background-color: #C8EEE5;
}

.bottom_area > span {
    white-space: break-spaces;
    padding-top: calc(16px + var(--CurrentScaleOption));
    font-weight: var(--FontWeightSemiBold);
    font-size: calc(17px + var(--CurrentScaleOption));
    color: var(--MainText);
    line-height: 23.8px;
}