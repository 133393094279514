.modal_content {
  background-color: white;
  border-radius: 5px;
  padding: 24px 0 0px 0;
  max-width:480px;
  min-width:320px;
  width: 100%;
  border-radius: 40px 40px 0 0;
  text-align: center;
  position: relative;
}