.custom_date_picker {
    position: relative;
    z-index: 99999;
    display: inline-flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    background-color: white;
    transition: height 0.5s ease;
}

.pencil_view_container {
    width: 2.56rem;
    height: 2.56rem;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
}

.static_calendar_sizing {
    height: 520.5px;
}

.text_field_sizing {
    height: 275.5px;
}

.pencil_view {
    cursor: pointer;
    width: 60%;
    height: 60%;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(98deg) brightness(200%) contrast(100%);
}

.header_container {
    width: 272px;
    height: 100px;
    background-color: #2196F3;
    color: white;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 16px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 16px;
}

.header_title_container {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
}

.select_date_title {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 2.66;
    letter-spacing: 0.08333em;
    text-transform: uppercase;
    color: white;
}

.header_title {
    font-size: 2.125rem;
    font-weight: 400;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    line-height: 1.235;
    letter-spacing: 0.00735em;
}

.custom_date_field_container {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}

.custom_date_field {
    width: 85%;
}

.custom_date_field label {
    color: #2196F3;
}

.action_button_text {
    text-decoration: none;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 8px;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    align-items: center;
    display: inline-flex;
    color: #2196F3;
}

.action_buttons_container {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.action_buttons_container_padding {
    padding: 8px;
}