.slide-in-bottom-enter-active {
    animation: 200ms cubic-bezier(0.65, 0, 0.35, 1) 0s 1 normal forwards running slide-in-bottom-open;
}


.slide-in-bottom-exit-done {
    animation: 200ms cubic-bezier(0.65, 0, 0.35, 1) 0s 1 normal forwards running slide-in-bottom-close;
}


@keyframes slide-in-bottom-open {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes slide-in-bottom-close {
    0% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(100%);
    }
}