.none { display: none !important;}

.cursor_pointer {
    cursor: pointer;
}

.bold1 {font-weight: 100;}
.bold2 {font-weight: 200;}
.bold3 {font-weight: 300;}
.bold4 {font-weight: 400;}
.bold5 {font-weight: 500;}
.bold6 {font-weight: 600;}
.bold7 {font-weight: 700;}
.bold8 {font-weight: 800;}
.bold9 {font-weight: 900;}

.ts9 {font-size: 9px;}
.ts10 {font-size: 10px;}
.ts11 {font-size: 11px;}
.ts12 {font-size: 12px;}
.ts13 {font-size: 13px;}
.ts14 {font-size: 14px;}
.ts15 {font-size: 15px;}
.ts16 {font-size: 16px;}
.ts17 {font-size: 17px;}
.ts18 {font-size: 18px;}
.ts19 {font-size: 19px;}
.ts20 {font-size: 20px;}
.ts21 {font-size: 21px;}
.ts22 {font-size: 22px;}
.ts23 {font-size: 23px;}
.ts24 {font-size: 24px;}
.ts25 {font-size: 25px;}
.ts26 {font-size: 26px;}
.ts27 {font-size: 27px;}
.ts28 {font-size: 28px;}
.ts29 {font-size: 29px;}
.ts30 {font-size: 30px;}
.ts31 {font-size: 31px;}
.ts32 {font-size: 32px;}
.ts33 {font-size: 33px;}
.ts34 {font-size: 34px;}
.ts35 {font-size: 35px;}
.ts36 {font-size: 36px;}
.ts37 {font-size: 37px;}
.ts38 {font-size: 38px;}
.ts39 {font-size: 39px;}
.ts40 {font-size: 40px;}
.ts50 {font-size: 50px;}

.mt1 {margin-top: 1px;}
.mt5 {margin-top: 5px;}
.mt10 {margin-top: 10px;}
.mt15 {margin-top: 15px;}
.mt20 {margin-top: 20px;}

.mb0 {margin-bottom: 0 !important;}
.mb1 {margin-bottom: 1px;}
.mb5 {margin-bottom: 5px;}
.mb10 {margin-bottom: 10px;}
.mb15 {margin-bottom: 15px;}
.mb20 {margin-bottom: 20px;}
.mb32 {margin-bottom: 32px;}
.mb40 {margin-bottom: 40px;}
.mb48 {margin-bottom: 48px;}

.ml1 {margin-left: 1px;}
.ml5 {margin-left: 5px;}
.ml14 {margin-left: 14px;}
.ml15 {margin-left: 15px;}
.ml10 {margin-left: 10px;}
.ml20 {margin-left: 20px;}
.ml24 {margin-left: 24px;}

.mr1 {margin-right: 1px;}
.mr5 {margin-right: 5px;}
.mr8 {margin-right: 8px;}
.mr10 {margin-right: 10px;}
.mr15 {margin-right: 15px;}
.mr20 {margin-right: 20px;}
.mr24 {margin-right: 24px;}

.p0 {padding: 0 !important;}
.p1 {padding: 1px;}
.p2 {padding: 2px;}
.p3 {padding: 3px;}
.p4 {padding: 4px;}
.p5 {padding: 5px;}
.p6 {padding: 6px;}
.p7 {padding: 7px;}
.p8 {padding: 8px;}
.p9 {padding: 9px;}
.p10 {padding: 10px;}
.p11 {padding: 11px;}
.p12 {padding: 12px;}
.p13 {padding: 13px;}
.p14 {padding: 14px;}
.p15 {padding: 15px;}
.p16 {padding: 16px;}
.p17 {padding: 17px;}
.p18 {padding: 18px;}
.p19 {padding: 19px;}
.p20 {padding: 20px;}
.p21 {padding: 21px;}
.p22 {padding: 22px;}
.p23 {padding: 23px;}
.p24 {padding: 24px;}
.p25 {padding: 25px;}
.p30 {padding: 30px;}
.p40 {padding: 40px;}

.pt0 {padding-top: 0 !important;}
.pt1 {padding-top: 1px;}
.pt2 {padding-top: 2px;}
.pt3 {padding-top: 3px;}
.pt4 {padding-top: 4px;}
.pt5 {padding-top: 5px;}
.pt6 {padding-top: 6px;}
.pt7 {padding-top: 7px;}
.pt8 {padding-top: 8px;}
.pt9 {padding-top: 9px;}
.pt10 {padding-top: 10px;}
.pt11 {padding-top: 11px;}
.pt12 {padding-top: 12px;}
.pt13 {padding-top: 13px;}
.pt14 {padding-top: 14px;}
.pt15 {padding-top: 15px;}
.pt16 {padding-top: 16px;}
.pt17 {padding-top: 17px;}
.pt18 {padding-top: 18px;}
.pt19 {padding-top: 19px;}
.pt20 {padding-top: 20px;}
.pt21 {padding-top: 21px;}
.pt22 {padding-top: 22px;}
.pt23 {padding-top: 23px;}
.pt24 {padding-top: 24px;}
.pt25 {padding-top: 25px;}
.pt30 {padding-top: 30px;}
.pt40 {padding-top: 40px;}
.pt70 {padding-top: 70px;}

.pb0 {padding-bottom: 0 !important;}
.pb1 {padding-bottom: 1px;}
.pb2 {padding-bottom: 2px;}
.pb3 {padding-bottom: 3px;}
.pb4 {padding-bottom: 4px;}
.pb5 {padding-bottom: 5px;}
.pb6 {padding-bottom: 6px;}
.pb7 {padding-bottom: 7px;}
.pb8 {padding-bottom: 8px;}
.pb9 {padding-bottom: 9px;}
.pb10 {padding-bottom: 10px;}
.pb11 {padding-bottom: 11px;}
.pb12 {padding-bottom: 12px;}
.pb13 {padding-bottom: 13px;}
.pb14 {padding-bottom: 14px;}
.pb15 {padding-bottom: 15px;}
.pb16 {padding-bottom: 16px;}
.pb17 {padding-bottom: 17px;}
.pb18 {padding-bottom: 18px;}
.pb19 {padding-bottom: 19px;}
.pb20 {padding-bottom: 20px;}
.pb21 {padding-bottom: 21px;}
.pb22 {padding-bottom: 22px;}
.pb23 {padding-bottom: 23px;}
.pb24 {padding-bottom: 24px;}
.pb25 {padding-bottom: 25px;}
.pb30 {padding-bottom: 30px;}
.pb40 {padding-bottom: 40px;}

.pl1 {padding-left: 1px;}
.pl2 {padding-left: 2px;}
.pl3 {padding-left: 3px;}
.pl4 {padding-left: 4px;}
.pl5 {padding-left: 5px;}
.pl6 {padding-left: 6px;}
.pl7 {padding-left: 7px;}
.pl8 {padding-left: 8px;}
.pl9 {padding-left: 9px;}
.pl10 {padding-left: 10px;}
.pl11 {padding-left: 11px;}
.pl12 {padding-left: 12px;}
.pl13 {padding-left: 13px;}
.pl14 {padding-left: 14px;}
.pl15 {padding-left: 15px;}
.pl16 {padding-left: 16px;}
.pl17 {padding-left: 17px;}
.pl18 {padding-left: 18px;}
.pl19 {padding-left: 19px;}
.pl20 {padding-left: 20px;}
.pl21 {padding-left: 21px;}
.pl22 {padding-left: 22px;}
.pl23 {padding-left: 23px;}
.pl24 {padding-left: 24px;}
.pl25 {padding-left: 25px;}
.pl30 {padding-left: 30px;}
.pl40 {padding-left: 40px;}

.pr1 {padding-right: 1px;}
.pr2 {padding-right: 2px;}
.pr3 {padding-right: 3px;}
.pr4 {padding-right: 4px;}
.pr5 {padding-right: 5px;}
.pr6 {padding-right: 6px;}
.pr7 {padding-right: 7px;}
.pr8 {padding-right: 8px;}
.pr9 {padding-right: 9px;}
.pr10 {padding-right: 10px;}
.pr11 {padding-right: 11px;}
.pr12 {padding-right: 12px;}
.pr13 {padding-right: 13px;}
.pr14 {padding-right: 14px;}
.pr15 {padding-right: 15px;}
.pr16 {padding-right: 16px;}
.pr17 {padding-right: 17px;}
.pr18 {padding-right: 18px;}
.pr19 {padding-right: 19px;}
.pr20 {padding-right: 20px;}
.pr21 {padding-right: 21px;}
.pr22 {padding-right: 22px;}
.pr23 {padding-right: 23px;}
.pr24 {padding-right: 24px;}
.pr25 {padding-right: 25px;}
.pr30 {padding-right: 30px;}
.pr40 {padding-right: 40px;}

.rounded10 {border-radius: 10px;}
.rounded11 {border-radius: 11px;}
.rounded12 {border-radius: 12px;}
.rounded13 {border-radius: 13px;}
.rounded14 {border-radius: 14px;}
.rounded15 {border-radius: 15px;}
.rounded16 {border-radius: 16px;}
.rounded17 {border-radius: 17px;}
.rounded18 {border-radius: 18px;}
.rounded19 {border-radius: 19px;}
.rounded20 {border-radius: 20px;}
.rounded25 {border-radius: 25px;}
.rounded30 {border-radius: 30px;}

.blue {color: #669AFF !important;}
.red {color: #F72B3A !important;}

.color-white {color: #fff;}
.bgcolor-white {background-color: #fff;}

.color-black {color: #000;}
.bgcolor-black {background-color: #000;}

.jeomsin-color-light-gray {color:#919AA6;}
.jeomsin-bgcolor-light-gray {color:#919AA6;}

.jeomsin-color-dark-yellow {color: #FADE4A;}
.jeomsin-bgcolor-dark-yellow {background-color: #FADE4A;}

.jeomsin-color-bright-yellow {color: #FFF855;}
.jeomsin-bgcolor-bright-yellow {background-color: #FFF855;}

.jeomsin-color-default-background {color: #F4F7FE;}
.jeomsin-bgcolor-default-background {background-color: #F4F7FE;}

.bgcolor-yellow {background-color: #FFF854 !important;}
.bgcolor-gray {background-color: #E9EBF4 !important;}
