.contents_description {
  white-space: pre-wrap;
  word-break: keep-all;
}

.modal {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
}

.dim {
  position: absolute;
  width: clamp(320px, 100vw, 480px);
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: clamp(320px, 100vw, 480px);
}

.spacer {
  height: 10px;
}

.cornerRadius5 {
  border-radius: 5px;
}

.cornerRadius25 {
  border-radius: 25px;
}

.cornerRadius20 {
  border-radius: 20px;
}

.modal_content {
  background-color: white;
  padding: 40px 24px 40px 24px;
  width: clamp(245px, 100vw, 405px);
  margin: 0 37.5px;
  text-align: center;
  position: relative;
}

.modal_content > .title {
  margin-bottom: 40px;
  font-size: 22px;
  font-weight: 700;
  line-height: 26.25px;
}

.modal_content.help_content {
  height: 154px;
}

.modal_content.saju_info_select {
  height: 250px;
}

.help_content > .help_text {
  margin-top: 20px;
  font-size: 12px;
  font-weight: 500;
  color: #F72B3A;
  line-height: 14.32px;
}

.close_button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 12px;
}

.button {
  cursor: pointer;
}

.opacity40 {
  opacity: 0.4;
}

.font_size_change_modal_content {
  border-radius: 40px;
}

.font_size_change_description_stack {
  display: inline-flex;
  width: 100%;
  text-align: center;
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  flex-direction: column;
}

.font_size_change_title {
  font-size: 22px;
  font-weight: 700;
}

.font_size_change_font_chooser_h_stack {
  width: 100%;
  height: 88px;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-around;
}

.font_size_change_font_chooser_v_stack {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
}

.font_size_change_font_small_example {
  font-size: 12px;
  font-weight: 600;
}

.font_size_change_font_medium_example {
  font-size: 15px;
  font-weight: 600;
}

.font_size_change_font_big_example {
  font-size: 18px;
  font-weight: 600;
}

.font_size_change_button {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #EAEEF7;
}

.font_size_change_checkbox_and_description_container {
  width: 47px;
  height: 16px;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.font_size_change_checkbox_button_image {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.font_size_change_description {
  font-size: 13px;
  font-weight: 500;
}

.spacer_height_30 {
  height: 30px;
}

.spacer_height_40 {
  height: 40px;
}

.confirmation_button {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  font-size: 16px;
  font-weight: 600;
  background-color: #FFF854;
  border-radius: 1000px;
}

.calendar_selection_container {}

.share_popup_title {
  margin-top: 12px;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #1F2024;
  text-align: left;
}

.share_popup_contents {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #1F2024;
  text-align: left;
  padding-top: 10px;
}

.share_popup_ad_banner {
  padding: 18px 0;
}

.share_popup_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 56px;
  background: #1F2024;
  border-radius: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
}

.ohang_graph_popup_title {
  margin-top: 12px;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #1F2024;
  text-align: center;
}

.ohang_graph_container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px 20px;
  background-color: #f5f9ff;
  border-radius: 15px;
  height: 170px;
}

.ohang_graph_container .bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  position: relative;
}

.ohang_graph_container .stick {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 20px;
}

.ohang_graph_container .fill {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #1F2024;
  border-radius: 20px;
  transition: background-color 0.3s;
}

.ohang_graph_container .bar.active .fill_end_point {
  width: 8px;
  height: 8px;
  border-radius: 100px;
  background-color: #ffffff;
  transform: translate(0, 2px);
}

.ohang_graph_container .label {
  margin-top: 8px;
  font-size: 14px;
  color: #4a4a4a;
}

.ohang_circle_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.ohang_item_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ohang_item_wrap .ohang_circle_title {
  padding-bottom: 10px;
}

.ohang_item_wrap .ohang_circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 100px;
}

.ohang_circle .ohang_circle_text {
  font-size: 24px;
  font-weight: 700;
  color:#ffffff;
}

.top_button_wrap {
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 12px;
}

.top_button_wrap .share {
  padding: 10px 10px 5px 10px;
  width: 20px;
  height: 20px;
}

.top_button_wrap .close {
  padding: 10px 10px 5px 10px;
  width: 20px;
  height: 20px;
}

.saju_info_popup_profile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.saju_info_popup_profile .ohang_img {
  width: 18px;
  height: 18px;
  transform: translate(0, 10px);
}

.saju_info_popup_profile .profile_img {
  width: 120px;
  height: 120px;
  background-color: #F4F7FE;
  border-radius: 100px;
}

.saju_info_popup_profile .profile_img.selected {
  background-color: #FFF854;
}

.saju_info_popup .name{
  font-size: 22px;
  font-weight: 700;
  color: #1F2024;
}

.saju_info_popup .birth{
  font-size: 12px;
  font-weight: 400;
  color: #1F2024;
}

.saju_info_popup .day{
  font-size: 16px;
  font-weight: 700;
  color: #1F2024;
}

.saju_info_popup .score{
  font-size: 20px;
  font-weight: 900;
  color: #1F2024;
}

.saju_info_popup .unse_text{
  font-size: 14px;
  font-weight: 400;
  color: #1F2024;
}

.saju_info_popup_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 56px;
  background: #FFF854;
  border-radius: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #1F2024;
}

.two_button_wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap:7px
}

.two_button_wrap .button:nth-child(1){
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 45px;
  background-color: #E0E0E0;
  border-radius: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--Button03);
}

.two_button_wrap .button:nth-child(2){
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 45px;
  background-color: var(--UiYellow);
  border-radius: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--Button03);
}

.modal_content .second_button {
  cursor: pointer;
  color: var(--SubText02);
}

.margin_top_20 {
  margin-top: 20px;
}
