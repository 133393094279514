.slider-dots {
    position: absolute;
    bottom: 16px;
    left: -5px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slider-dots li {
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    /* margin: 0 5px; */
    padding: 0;
    cursor: pointer;
}

.slider-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 10px;
    height: 10px;
    /* padding: 5px; */
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.slider-dots li button:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: #ffffff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slider-dots li.slick-active button:before { /* moudle css로 처리 시 slick-active를 인식못함*/
    opacity: 1;
    color: #ffffff;
}