.modal {
  position: fixed;
  top: 0;
  height: 100%;
  display: flex;
  align-items: end;
  z-index: 9999;
}

.dim {
  position: absolute;
  width: 100%;
  height: 100%;
}

.dim_color {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal_content {
  background-color: white;
  border-radius: 5px;
  padding: 12px 0 40px 0;
  width: 100%;
  border-radius: 40px 40px 0 0;
  text-align: center;
  position: relative;
}

@keyframes slideOut {
  to { transform: translateY(100%); }
}

@keyframes slideIn {
  from { transform: translateY(100%); }
}

::view-transition-old(bottom_popup_transitionable) {
  animation-name: slideOut;
}

::view-transition-new(bottom_popup_transitionable) {
  animation-name: slideIn;
}

.modal_content .title {
  margin-bottom: 30px;
  font-size: 22px;
  font-weight : 700;
  line-height: 26.25px;
}

.modal_content .contents {
  white-space: pre-wrap;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
}

.close_button_wrap{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 48px;
  padding-right: 24px;
}

.close_button {
  cursor: pointer;
}

.button_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button_wrap_row{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 24px;
  gap:8px
}

.button {
  width: 90%;
  height: 56px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 16px;
}

.button_small {
  width: 30%;
  height: 56px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: var(--FontWeightMedium);
  font-size: 14px;
  color: var(--SubBg01);
  margin-bottom: 16px;
  background-color: var(--MainText);
}

.button_large {
  width: 70%;
  height: 56px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: var(--FontWeightBold);
  font-size: 14px;
  color: var(--MainText);
  margin-bottom: 16px;
  background-color: var(--PointColor01);
}

.login_text_wrap {
  display: flex;
  justify-content: space-around;
}

.login_text {
  display: grid;
  text-align: left;
}

.login_main_text {
  font-size: 19px;
  font-weight: 700;
  line-height: 22.67px;
}

.find_account{
  border-bottom: 1px solid #818C9A;
}

.login_sub_text {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  height: 17px;
  color: #818C9A;
}

.sns_wrap {
  height: 60px;
}

.sns_wrap > img {
  margin-right: 16px;
}

.sns_wrap > :last-child {
  margin-right: 0;
}

@media (max-width: 376px) {
  .modal_content .contents {
    overflow-y: scroll;
    max-height: 320px;
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
  }
  /* ( 크롬, 사파리, 오페라, 엣지 ) 동작 */
  .modal_content .contents::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 420px) {
  .modal_content .contents {
    overflow-y: scroll;
    max-height: 320px;
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
  }
  /* ( 크롬, 사파리, 오페라, 엣지 ) 동작 */
  .modal_content .contents::-webkit-scrollbar {
    display: none;
  }
}

.blurred {
  backdrop-filter: blur(5px);
}

.unblurred {
  backdrop-filter: none;
}

.checkbox_wrap {
  padding: 15px 15px 0 15px;
  text-align: left;
}

.checkbox_wrap label {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  white-space: break-spaces;
}

.checkbox_wrap label::before {
  content: '';
  display: inline-block;    
  height: 20px;   
  width: 20px;        
  margin-right: 10px;
  background-image: url('/images/member_integrated/uncheckedbox_small.png');
}

.checkbox_wrap input[type="checkbox"] {
  margin-right: 10px;
}

.agreement_wrap {
  display: flex;
  align-items: center;
  border: 1px solid #EAEEF7;
  border-radius: 12px;
  padding: 19px;
  margin-bottom: 30px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.agreement_wrap label {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.09px;
  margin-left: 10px;
}

.agreement_wrap label::before {
  content: '';
  display: inline-block;    
  height: 20px;   
  width: 20px;        
  margin-right: 10px;
  background-image: url('/images/member_integrated/uncheckbox.png');
}

.underline {
  text-decoration:underline
}

.privacy_usage {
  padding: 0 24px;
}

.privacy_usage .title {
  font-size: 17px;
  font-weight: 600;
  line-height: 20.29px;
  text-align: left;
}

.privacy_usage table {
  width: 100%;
  border-collapse: collapse;
}

.privacy_usage table th {
  font-weight: 800;
  text-align: center;
  width:80px;
  padding: 21px 17px 21px 17px;
  background-color: #F4F7FE;
}

.privacy_usage table td {
  text-align: left;
  font-weight: 500;
  padding: 0 16px 0 16px;
}

.privacy_usage table th, .privacy_usage table td {
  font-size: 12px;
  line-height: 16.8px;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  color: #818C9A;
}

.privacy_usage .note {
  font-size: 12px;
  font-weight: 500;
  color: #818C9A;
  margin-top: 10px;
  line-height: 19.2px;
  text-align: left;
}

.relation_title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left;
}

.relation_button {
  display: inline-block;
  padding: 10px 20px;
  margin: 5px;
  font-size: 14px;
  border: 1px solid  var(--Line02);
  border-radius: 20px;
  background-color: var(--SubBg01);
  font-weight: var(--FontWeightRegular);
  color: var(--SubText02);
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.selected {
  font-weight: var(--FontWeightBold);
  background-color: var(--PointColor01);
  color: var(--MainText);
  border-color: var(--PointColor01);
}

.relation_container {
  text-align: left;
  margin-bottom: 20px;
}

.relation_container .relation_button {
  margin-right: 10px;
}

.dream_category_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  white-space: pre-wrap;
  gap:8px;
}

.dream_category_container button {
  height: 56px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #1F2024;
  background: #E9EBF4;
  border-radius: 12px;
  border: 0;
  cursor: pointer;
}

.dream_category_container button:nth-last-child(1):nth-child(odd) {
  grid-column: span 2;
}

.dream_detail_category_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.dream_detail_contents_container {
  width: 80%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  white-space: pre-wrap;
  gap:8px;
  justify-items: start;
}

.dream_detail_contents_container button:nth-last-child(1):nth-child(odd) {
  grid-column: span 2;
}

.dream_detail_contents_container button {
  height: 44px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #1F2024;
  background: #FFFFFF;
  border: 0;
  cursor: pointer;
}

.terms_of_use_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 40px 40px 0 0;
  width: 100%;
  transition: transform 500ms;
}

.terms_of_use__close_btn_container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  margin-top: 12px;
}

.terms_of_use__close_btn {
  width: 24px !important;
  height: 24px !important;
  object-fit: contain;
  padding: 12px 12px 12px 12px;
  margin-right: 6px;
  cursor: pointer;
}

.terms_of_use__title {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 20px;
}

.terms_of_use__description {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: var(--SubText01);
  text-align: center;
  margin-bottom: 40px;
}

.terms_of_use__description > u {
  cursor: pointer;
}

.terms_of_use__start_btn {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.terms_of_use__start_btn__title {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: var(--MainButton);
}

.terms_of_use_show {
  transform: translateY(0%);
}

.terms_of_use_hide {
  transform: translateY(100%);
}
