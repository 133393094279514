.modal_content {
  background-color: white;
  border-radius: 5px;
  padding: 12px 0 40px 0;
  max-width:480px;
  min-width:320px;
  width: 100%;
  border-radius: 40px 40px 0 0;
  text-align: center;
  position: relative;
}

.modal_content .title_wrap {
  padding-left: 24px;
}

.modal_content .title_icon {
  width: calc(17px + var(--CurrentScaleOption));
  height: calc(17px + var(--CurrentScaleOption));
}

.modal_content .title {
  margin-bottom: 21px;
  font-size: calc(14px + var(--CurrentScaleOption));
  font-weight: var(--FontWeightSemiBold);
  text-align: start;
  color:var(--MainText);
}

.modal_content .contents {
  color:var(--MainText);
}
  
@media (max-width: 376px) {
  .modal_content .contents {
    overflow-y: scroll;
    max-height: 320px;
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
  }
  /* ( 크롬, 사파리, 오페라, 엣지 ) 동작 */
  .modal_content .contents::-webkit-scrollbar {
    display: none;
  }
}
  
@media (max-width: 420px) {
  .modal_content .contents {
    overflow-y: scroll;
    max-height: 320px;
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
  }
  /* ( 크롬, 사파리, 오페라, 엣지 ) 동작 */
  .modal_content .contents::-webkit-scrollbar {
    display: none;
  }
}

.guide_item .main_text {
  font-size: calc(11px + var(--CurrentScaleOption));
  font-weight: var(--FontWeightMedium);
  color:var(--MainText);
}

.guide_item .sub_text {
  font-size: calc(11px + var(--CurrentScaleOption));
  font-weight: var(--FontWeightExtraLight);
  color:var(--SubText01);
  text-align: start;
}

.close_button {
  font-size: calc(14px + var(--CurrentScaleOption));
  font-weight: var(--FontWeightBold);
  color:var(--MainText);
  cursor: pointer;
}

.close_button > span {
  background: linear-gradient(#FFF854, #FFF854);
  background-size: auto 40%;
  background-position-y: 100%;
  background-repeat: no-repeat;
}