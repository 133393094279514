.select-box-wrap {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.select-box-wrap .select-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:0;
    border-bottom:0;
}

.select-box-wrap .select-content > button{
    border: 0;
    background-color: #F8F8F8;
    color: #BCC3D3;
    border-radius: 40px;
    width: 100%;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 19.09px;
}

.select-box-wrap .select-content .selected {
    background-color: #000000;
    color: #ffffff;
}