.buttonContainer {
    display: flex;
    font-size: 16px;
    font-weight: 600;
    color: var(--MainText);
    justify-content: center;
    width: calc(100% - 48px);
}

.button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}