.fortune_wrap {
    padding-top:44px;
    padding-bottom: 56px;
}

.fortune_wrap .title_area {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: calc(48px + var(--CurrentScaleOption));
    background-color: var(--SubBg01);
}

.title_area .title{
    height:calc(26px + var(--CurrentScaleOption));
    font-size:calc(26px + var(--CurrentScaleOption));
    font-weight:var(--FontWeightBold); 
    flex-grow:1; 
    line-height:26.4px;
}

.title_area .tab_right {
    display:flex;
    align-items:center;
    cursor: pointer;
}

.title_area .tab_right_text> :nth-child(1) {
    font-size:calc(13px + var(--CurrentScaleOption));
    font-weight: var(--FontWeightMedium);
    line-height: 15.51px;
    color: var(--SubText01);
}

.title_area .tab_right_text > :nth-child(2) {
    font-size:calc(15px + var(--CurrentScaleOption));
    font-weight: var(--FontWeightSemiBold);
    line-height: 17.9px;
}

.title_area img {
    width: calc(14px + var(--CurrentScaleOption));
    height: calc(14px + var(--CurrentScaleOption));
}

.fortune_main_banner {
    text-align:center; 
    outline: none;
}

.fortune_main_banner img {
    width: 100%;
    object-fit: fill;
}

.fortune_main_banner_loading {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.menu_box {
    border:1px solid #F6F6F6;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    place-items:center;
    padding: 24px 16px;
}

.menu_box_slide_wrap {
    width: 100%;
}

.menu_box_slide_dot {
    /* width: 100%; */
}

.menu_box.none_padding {
    position: relative;
    padding: 0px;
}

.menu_box .text_area {
    padding-bottom: 20px;
}

.menu_box .main_text {
    font-weight: var(--FontWeightBold);
    font-size: calc(22px + var(--CurrentScaleOption));
    line-height: 26.25px;
    color:#1F2024;
}

.menu_box .sub_text {
    font-weight: var(--FontWeightMedium);
    font-size: calc(14px + var(--CurrentScaleOption));
    line-height: 16.8px;
    color:var(--SubText01);
    padding-bottom: calc(4px + var(--CurrentScaleOption));
}

.menu_box .type1 {
    display:flex;
    flex-direction:column; 
    align-items:center; 
    margin-top:10px;
    margin-bottom:10px;
}

.menu_box .type1 > .box_item {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box_pack: center;
    justify-content: center;
    cursor: pointer;
    height: calc(66px + var(--CurrentScaleOption));
}

.menu_box .type1 > .box_item img{
    width: calc(48px + var(--CurrentScaleOption));
    height: calc(48px + var(--CurrentScaleOption));
}

.type1 .box_item > span {
    font-weight: var(--FontWeightSemiBold);
    font-size: calc(13px + var(--CurrentScaleOption));
    line-height: 16px;
    margin-top: calc(2px + var(--CurrentScaleOption));
    color: var(--MainText);
}

.menu_box .type2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    cursor: pointer;
}

.menu_box .type2 > .box_item {
    display:flex;
    align-items: center;
}

.menu_box .type2 .img_box {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F4F7FE;
    border-radius: 100%;
    width: calc(64px + var(--CurrentScaleOption));
    height: calc(64px + var(--CurrentScaleOption));
}

.menu_box .type2 .img_box > img {
    height: calc(48px + var(--CurrentScaleOption));
}

.menu_box .type2 .text_box {
    display: flex;
    flex-direction: column;
    margin-left: 14px;
}

.type2 .text_box > .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    font-weight: var(--FontWeightSemiBold);
    font-size: calc(17px + var(--CurrentScaleOption));
    line-height: 20px;
    color: var(--MainText);
}

.type2 .text_box > .description {
    font-weight: var(--FontWeightMedium);
    font-size: calc(14px + var(--CurrentScaleOption));
    line-height: 17px;
    color: #818C9A;
    margin-top: calc(6px + var(--CurrentScaleOption));
}

.menu_box .type3 {
}

.menu_box .type3 > .box_item {
    display: flex;
    flex-direction: column;
    position : relative;
    height: calc(145px + var(--CurrentScaleOption));
    background-color: var(--MainBg);
    cursor: pointer;
}

.menu_box .type3:nth-child(2) > .box_item {
    border-top-left-radius: 12px;
}

.menu_box .type3:nth-child(3) > .box_item {
    border-top-right-radius: 12px;
}

.menu_box .type3:nth-child(4) > .box_item {
    border-bottom-left-radius: 12px;
}

.menu_box .type3:nth-child(5) > .box_item {
    border-bottom-right-radius: 12px;
}

.menu_box .type3 .text_box {
    display: flex;
    flex-direction: column;
}

.menu_box .type3 img {
    right: 0;
    bottom: 0;
    position: absolute;
    width: calc(35px + var(--CurrentScaleOption));
    height: calc(35px + var(--CurrentScaleOption));
    margin-right: 13px;
    margin-bottom: 14px;
}

.menu_box .type3 .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    font-weight: var(--FontTypeWSubTitle2);
    font-size: var(--FontTypeSSubTitle2);
    line-height: 20px;
    color: var(--MainText);
    padding: 14px 0 0 14px;
}

@media (max-width: 479px) {
    .menu_box .type3 .title {
        display:block;
    }
  }

.menu_box .type3 .description {
    font-weight: var(--FontTypeWBody2);
    font-size: var(--FontTypeSBody2);
    line-height: 17px;
    color: var(--SubText01);
    padding: 6px 0 0 14px;
}

.type4_padding_wrap {
    width: calc(100% - 48px);
    padding: 24px 16px 0 16px;
}

.menu_box .type4 {
    display: flex;
    flex-direction: column;
    text-overflow:ellipsis; 
    white-space:nowrap;
    overflow:hidden;
    justify-content: center;
    border-bottom: 1px solid #EAEEF7;
}

.type4 .box_item {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 18px 0;
    cursor: pointer;
}

.menu_box .type4:last-child {
    border-bottom: 0;
}

.type4 .box_item > .hash_tag {
    font-weight: var(--FontWeightMedium);
    font-size: calc(14px + var(--CurrentScaleOption));
    line-height: 17px;
    color: var(--PointColor02);
    margin-right: 8px;
}

.type4 .box_item > .content {
    font-weight: var(--FontWeightSemiBold);
    font-size: calc(17px + var(--CurrentScaleOption));
    line-height: 20px;
    color: var(--MainText);
    margin-right: 5px;
}

.type4_button_wrap {
    width: 100%;
    text-align: center;
    padding: 20px 44px 22px 44px;
    border-top: 1px solid #EAEEF7;
    cursor: pointer;
}

.type4_button_wrap .button{
    font-weight: var(--FontWeightSemiBold);
    font-size: calc(16px + var(--CurrentScaleOption));
    line-height: 19px;
    text-align: center;
    color: var(--MainText);
}

.slider_container {
    border-radius: calc(12px + var(--CurrentScaleOption));
    overflow: hidden;
    margin-bottom: 16px;
}

.aspect_fit {
    object-fit: contain;
    width: 100%;
}

.app_content_title_wrap {
    display: flex;
    flex-direction: row;
    gap: 6px;
    justify-content: flex-start;
    align-items: center;
}

@media (max-width: 359px) {
    .app_content_title_wrap {
        flex-direction: column;
        align-items: baseline;
    }
}