.bottom_sticked {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 480px;
    min-width: 320px;
    /* height:112px; */
    pointer-events: none;
    z-index: 1000;
}

.bottom_nav_centered {
    width: 100%;
}

.botttom_nav {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 480px;
    min-width: 320px;
    border-top: 0.5px solid var(--Line05);
    border-left: 0.5px solid var(--Line05);
    border-right: 0.5px solid var(--Line05);
}

.botttom_nav > button {
    margin:0;
    padding-bottom: 4px;
}

.botttom_nav .tab_name {
    position: relative;
    color:#000000;
}

.botttom_nav .tab_name.on:before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 1000px;
    top: 0%;
    left: 0%;
    transform: translate(-40%, -210%);
    background-color: #FFF854;
    z-index: -1;
}

.stackable {
    position: relative;
    width: 100%;
    height: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    pointer-events: none;
    background-color: var(--MainBg);
}

.pointer_event_enabled {
    pointer-events: all;
}

.toast_popup_on_top {
    position: absolute;
    width: 100%;
    top: -56px;
}

.button_wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width:100%;
    max-width: 480px;
    min-width: 320px;
    height: 56px;
    background-color: var(--PointColor01);
    border-right: 1px solid #EEEEEE;
    border-left: 1px solid #EEEEEE;
}

.button_wrap .button {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(15px + var(--CurrentScaleOption));
    font-weight: var(--FontWeightBold);
    line-height: calc(19px + var(--CurrentScaleOption));
    flex-grow: 1;
    pointer-events: auto;
    cursor: pointer;
}