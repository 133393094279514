.modal_content {
  background-color: white;
  border-radius: 5px;
  padding: 0 24px;
  max-width:432px;
  min-width:272px;
  width: 100%;
  border-radius: 40px 40px 0 0;
  text-align: center;
  position: relative;
}

.title {
  font-size: calc(22px + var(--CurrentScaleOption));
  font-weight: var(--FontWeightExtraBold);
  color:var(--MainText);
}

.normal_text {
  font-size: calc(14px + var(--CurrentScaleOption));
  font-weight: var(--FontWeightExtraLight);
  line-height: calc(24px + var(--CurrentScaleOption));
  color:var(--MainText);
  word-break: keep-all;
  text-align: center;
}

.bold {
  font-size: calc(16px + var(--CurrentScaleOption));
  font-weight: var(--FontWeightExtraBold);
  color:var(--MainText);
}

.button_wrap {
  width: 100%;
}

.button {
  padding:calc(21px + var(--CurrentScaleOption)) calc(54px + var(--CurrentScaleOption));
  font-size: calc(14px + var(--CurrentScaleOption));
  font-weight: var(--FontWeightExtraBold);
  color:var(--MainText);
  border-radius: calc(12px + var(--CurrentScaleOption));
  cursor: pointer;
}

.gray {
  background-color: var(--ThirdButton);
}

.yellow {
  background-color: var(--PointColor01);
}