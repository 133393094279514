.divider_container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.divider {
    content: "";
    width: 1px;
}