.fade-enter-active {
    animation: fadeIn 600ms;
}

.fade-exit-active {
    animation: fadeOut 600ms;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}