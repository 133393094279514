.coupang_line_ad_container {
    width: 100%;
    height: 100px;
    background-color: var(--SubBg01);
    max-width: 480px;
    pointer-events: all;
}

.coupang_line_ad_container img {
    position: relative;
    height: 100px;
    object-fit: contain;
}

.coupang_line_ad_container img:nth-child(1) {
    z-index:1;
}