.dim {
  position: fixed;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9000;
  top: 0;
}

.blurred {
  backdrop-filter: blur(5px);
}

.unblurred {
  backdrop-filter: none;
}
