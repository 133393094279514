@media (max-width: 1139px) {
    .aside-wrap {
        display: none !important;
    }
}

@media (max-height: 860px) {
    .logo-wrap {
        margin-top: 10px;
    }
}

.aside-wrap {
    /* margin-left: 68px; */
    position: fixed;
    width: 540px;
    height:100vh;
    left: calc(50% - 510px);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: baseline;
    justify-items: center;
}

.logo-wrap {
    width: 71px;
    height: 64px;
    margin-bottom: 7.3vh;
}

.logo-wrap--img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.content-wrap {
    margin-bottom: 52px;
}

.main-content-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.coupon-wrap {
    display: flex;
    margin-top: 54px;
    width: 239px;
    height: 119px;
}

.coupon-text {
    background-color: #FFFFFF;
    width: 177px;
    height: 100%;
    border-radius: 12px;
}

.coupon-text > :nth-child(1) {
    margin:31px 0 0 20px;
    line-height:20.8px;
}

.coupon-text > :nth-child(2) {
    margin-left:20px;
    line-height:32.5px
}

.coupon-download {
    background-color: #FFF854;
    width: 62px;
    height: 100%;
    margin: 0;
    border-radius: 12px;
}

.coupon-download > img {
    width: 36px;
    height: 36px;
    margin: 41px 0 0 13px;
}

.banner-wrap {
    position: relative;
    display: flex;
    cursor: pointer;
    margin-bottom: 2.5vh;
}

.banner-text-wrap {
    width: 540px;
    height: 80px;
    margin-top: 38px;
    background-color: #669AFF;
    color: #FFFFFF;
    border-radius: 16px;
}

.banner-text {
    margin: 18px 0 0 28px;
}

.banner-text > :nth-child(1) {
    line-height: 23.87px;
}

.banner-text > :nth-child(3) {
    line-height: 16.71px;
    opacity: 0.78;
}

.banner-wrap img{
    position: absolute;
    right: 30px;
    bottom: 10px;
}

.footer-wrap {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 3.5vh;
}

.app-link-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.store-wrap {
    display: flex;
    /* margin-top: 27px; */
}

.store-wrap > div {
    display: flex;
    align-items: center;
}

.store-wrap span {
    line-height: 19.09px;
    color: #B1B1B1;  
}

.store-wrap .play-stroe {
    width: 115px;
    filter: grayscale(100%);
    cursor: pointer;
}

.store-wrap .app-stroe {
    width: 101px;
    filter: grayscale(100%);
    cursor: pointer;
}

.qrcode-wrap {
    width: 208px;
    height: 74px;
    background-color: var(--SubBg01);
    display: flex;
    align-items: center;
    flex-direction: row;
    border-radius: 12px;
    gap: 10px;
    position: relative;
    cursor: pointer;
}

.qrcode {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 12px;
}

.aside_page_qr_code_container {
    width: 46px;
    min-width: 46px;
    max-width: 46px;
    height: 46px;
    min-height: 46px;
    max-height: 46px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.aside_page_top_intro_font {
    color: #777777;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 1.9vh;
}

.aside_page_top_big_title {
    color: #333333;
    font-size: 54px;
    font-weight: 700;
    line-height: 64.8px;
    margin-bottom: 6.9vh;
}

.aside_page_top_big_title_blue {
    color: #669AFF;
    line-height: 64.8px;
}

.aside_page_qr_code_description_stack {
    line-height: 22.1px;
}

.aside_page_qr_code_description_stack_top {
    font-size: 16px;
    font-weight: 700;
    color: var(--MainText);
}

.aside_page_qr_code_description_stack_bottom {
    font-size: 13px;
    font-weight: 400;
    color: #979797;
}

.aside_page_magnifying_glass {
    position: absolute;
    top: 0;
    right: 0;
    margin: 8px 8px 0 0;
}

.growable_140 {
    flex-grow: 140;
}

.growable_90 {
    flex-grow: 90;
}