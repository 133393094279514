.counselor_alarm_container {

}

.counselor_alarm_container .title {
  margin-top: 12px;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #1F2024;
  text-align: center;
}

.contents {

}

.contents .alert_info_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 15px;
  background-color: #f5f9ff;
  border-radius: 15px;
}

.alert_info_container img{
  width: 61px;
  height: 43px;
}

.alert_info_container span{
  font-size: calc(14px + var(--CurrentScaleOption));
  font-weight: var(--FontWeightMedium);
  color: var(--MainText);
}

.contents .label {
  text-align: start;
  font-size: calc(13px + var(--CurrentScaleOption));
  font-weight: var(--FontWeightRegulra);
  color: var(--SubText01);
}

.button_container {

}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 56px;
  background: #FFF854;
  border-radius: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #1F2024;
}

.button:nth-child(1) {
  font-size: calc(14px + var(--CurrentScaleOption));
  font-weight: var(--FontWeightMedium);
  color: var(--ButtonText01);
  background-color: var(--PointColor01);
  margin-bottom: 12px;
}

.button:nth-child(2) {
  font-size: calc(14px + var(--CurrentScaleOption));
  font-weight: var(--FontWeightMedium);
  color: var(--ButtonText01);
  background-color: var(--ThirdButton);
}