.circle_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0 30px 0;
}

.circle {
    /* cursor: pointer; */
    margin: 10px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    position: relative;
    border: 1px solid #F9F9F9;
}

.pie {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
}

.circle {
    fill: none;
    /* stroke: #00d4ff; */
    stroke-linecap: round;
    stroke-width: 1;
}

.circle_title {
    font-size: 13px;
    font-weight: 600;
    color: #555;
}

.circle_title.type{
    font-size: 13px;
    font-weight: 700;
    color: #555;
}

.circle_title.time {
    font-size: 13px;
    font-weight: 400;
    color: #555;
}

.wrap_txt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
    font-weight: 700;
}

.circle .cap {
    /* fill: #00d4ff; */
}


.unse_deactivate {
    object-fit: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    position: relative;
    border: 1px solid #F9F9F9;
}

.strong_shadow {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
}

.weak_shadow {
    box-shadow: 0 0 10px #F9F9F9;
}