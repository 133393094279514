.statusbar {
    background-color: #ffffff;
    position: fixed;
    height: 44px;
    width: 100%;
    max-width: 480px;
    min-width: 320px;
    z-index: 1000;
}

.statusbar-wrap {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding:0 24px;
}

.statusbar-left {
    display:inline-flex;
    flex-grow: 1;
    height:32px;
    align-items:center; 
    font-weight:500; 
}

.statusbar-left-text {
    font-size:9px;
    font-weight:500;
    line-height:14.4px;
    margin-right:5px;
}

.statusbar-left img{
    height:18px;
}

.statusbar-right {
    display: flex;
    right: 0;
    height: 36px;
    flex-direction: row;
    align-items: center;
}

.statusbar-right > img {
    cursor: pointer;
}

.statusbar-right > img:nth-child(1) {
    width: 34px;
    height: 34px;
    margin-right: 5px;
}

.statusbar-right > img:nth-child(2) {
    width: 28px;
    height: 28px;
    object-fit: none;
    /* padding-left: 5px; */
    /* padding-right: 10px; */
}

.statusbar-right--circular-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    background-color: var(--SecondButton);
    border-radius: 50%;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.statusbar-right--circular-container--img {
    width: calc(11px + var(--CurrentScaleOption));
    height: calc(13px + var(--CurrentScaleOption));
}