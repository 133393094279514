.button_area {
    height: 60px;
    background-color: var(--MainBg);
    padding:0 32px;
}

.button_area .close_btn {
    font-size: calc(14px + var(--CurrentScaleOption));
    font-weight: var(--FontWeightMedium);
    color:var(--MainText);
}

.button_area > .coupang_btn {
    font-size: calc(14px + var(--CurrentScaleOption));
    font-weight: var(--FontWeightBold);
    color:var(--TextRed);
}