
  
  .modal_content {
    background-color: white;
    border-radius: 5px;
    padding: 12px 0 40px 0;
    max-width:480px;
    min-width:320px;
    width: 100%;
    border-radius: 40px 40px 0 0;
    text-align: center;
    position: relative;
  }
  
  .modal_content .title {
    margin-bottom: 30px;
    font-size: 22px;
    font-weight: 700;
    line-height: 26.25px;
    text-align: start;
    padding-left: 24px;
  }
  
  .modal_content .contents {
    white-space: pre-wrap;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
  }
  
  .close_button_wrap{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 48px;
    padding-right: 24px;
  }
  
  .close_button {
    cursor: pointer;
  }
  
  .button_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:8px
  }
  
  .button_small {
    width: 30%;
    height: 56px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--FontWeightMedium);
    font-size: 14px;
    color: var(--SubBg01);
    margin-bottom: 16px;
    background-color: var(--MainText);
    cursor: pointer;
  }
  
  .button_large {
    width: 70%;
    height: 56px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--FontWeightBold);
    font-size: 14px;
    color: var(--MainText);
    margin-bottom: 16px;
    background-color: var(--PointColor01);
    cursor: pointer;
  }
  
  @media (max-width: 376px) {
    .modal_content .contents {
      overflow-y: scroll;
      max-height: 320px;
      -ms-overflow-style: none; /* 인터넷 익스플로러 */
      scrollbar-width: none; /* 파이어폭스 */
    }
    /* ( 크롬, 사파리, 오페라, 엣지 ) 동작 */
    .modal_content .contents::-webkit-scrollbar {
      display: none;
    }
  }
  
  @media (max-width: 420px) {
    .modal_content .contents {
      overflow-y: scroll;
      max-height: 320px;
      -ms-overflow-style: none; /* 인터넷 익스플로러 */
      scrollbar-width: none; /* 파이어폭스 */
    }
    /* ( 크롬, 사파리, 오페라, 엣지 ) 동작 */
    .modal_content .contents::-webkit-scrollbar {
      display: none;
    }
  }
  
  .counselor_filter_title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
  }
  
  .counselor_filter_button {
    display: inline-block;
    padding: 12px 16px;
    margin-right: 8px;
    margin-bottom: 8px;
    font-size: 13px;
    border: 1px solid  var(--Line02);
    border-radius: 20px;
    background-color: var(--SubBg01);
    font-weight: var(--FontWeightRegular);
    color: var(--SubText02);
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .selected {
    font-weight: var(--FontWeightBold);
    background-color: var(--PointColor01);
    color: var(--MainText);
    border-color: var(--PointColor01);
  }
  
  .counselor_filter_container {
    text-align: left;
    margin-bottom: 20px;
  }
  
  .counselor_filter_container .counselor_filter_button {
    margin-right: 10px;
  }
  