.content_body_background {
    height: 100vh;
    width: 100%;
    max-width: 480px;
    position: fixed;
    z-index: -1;
    top: 0;
    border-right: 1px solid #EEEEEE;
    border-left: 1px solid #EEEEEE;
    transform: translateX(-1px);
}