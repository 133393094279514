.company_info_container {
  width: calc(100% - 48px);
}

.company_info_title {
  font-weight: var(--FontWeightBold);
  font-size: 12px;
  color: var(--MainText);
  margin: 0;
  padding: 0;
}

.company_info_detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  font-weight: var(--FontWeightRegular);
  font-size: 11px;
  line-height: 163%;
  padding: 0;
  margin: 9;
  color: var(--SubText01);
}

.company_info_telephone {
  color: var(--PointColor03);
  cursor: pointer;
}